exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-experience-js": () => import("./../../../src/pages/experience.js" /* webpackChunkName: "component---src-pages-experience-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-templates-blog-tag-page-temp-js": () => import("./../../../src/templates/blogTagPageTemp.js" /* webpackChunkName: "component---src-templates-blog-tag-page-temp-js" */),
  "component---src-templates-blog-temp-js": () => import("./../../../src/templates/blogTemp.js" /* webpackChunkName: "component---src-templates-blog-temp-js" */),
  "component---src-templates-experience-temp-js": () => import("./../../../src/templates/experienceTemp.js" /* webpackChunkName: "component---src-templates-experience-temp-js" */),
  "component---src-templates-project-temp-js": () => import("./../../../src/templates/projectTemp.js" /* webpackChunkName: "component---src-templates-project-temp-js" */)
}

